import styled from "@emotion/styled"
import { mq, Text, theme } from "@pallet-hq/hegel"
import Tooltip from "components/Tooltip"
import { ComponentType } from "react"

export type ItemInfoProps = {
    icon?: ComponentType<any>
    text: string
    color?: string
    tooltip?: string
    disabled?: boolean
}

export const InfoWithIcon = ({
    icon,
    text,
    color = theme.colors.black,
    tooltip = "",
    disabled = false,
}: ItemInfoProps) => {
    const Icon = icon || (() => <></>)
    return (
        <ItemInfoDiv disabled={disabled}>
            {icon && (
                <IconDiv>
                    <Icon size={24} color={color} />
                </IconDiv>
            )}
            {tooltip && <Tooltip />}
            <OverflowDiv>
                <Text
                    variant="paragraphMedium"
                    color={color}
                    data-tip={tooltip}
                    mt="xxsmall"
                >
                    {text}
                </Text>
            </OverflowDiv>
        </ItemInfoDiv>
    )
}

const OverflowDiv = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const ItemInfoDiv = styled.div`
    &:not(:last-of-type) {
        margin-right: ${theme.space.small};
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    ${mq({ paddingBottom: [theme.space.xxsmall, 0] })};
    opacity: ${({ disabled }: { disabled: boolean }) => (disabled ? 0.5 : 1)};
`

const IconDiv = styled.div`
    & svg {
        width: 16px;
        height: 16px;
    }

    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-right: ${theme.space.xsmall};
`
