import { gql, useMutation } from "@apollo/client"
import styled from "@emotion/styled"
import { FormModal, notify, TextAreaField } from "@pallet-hq/hegel"
import { Formik } from "formik"
import mixpanel from "mixpanel-browser"
import * as Yup from "yup"
import { TRACK_EVENTS } from "constants/events"
import { whiteSpaceValidator } from "utils/validators"
import { ApproveDeny_post } from "__generated__/ApproveDeny_post"
import { ApprovePostMutation } from "__generated__/ApprovePostMutation"
import { usePalletContext } from "utils/hooks/usePalletContext"

const APPROVE_POST_MUTATION = gql`
    mutation ApprovePostMutation(
        $postId: String!
        $applylist: String!
        $curatorComment: String
    ) {
        approvePost(
            postId: $postId
            applylist: $applylist
            curatorComment: $curatorComment
        ) {
            listing {
                id
                statusEnum
                isReviewable
                isListed
                applylist {
                    id
                    postCount
                    reviewCount
                }
            }
        }
    }
`

export type ApprovePostModalProps = {
    post: ApproveDeny_post
    isOpen: boolean
    hideModal: () => void
}

const ApprovePostModal = (props: ApprovePostModalProps) => {
    const [approvePost] = useMutation<ApprovePostMutation>(
        APPROVE_POST_MUTATION,
        { onCompleted: () => notify.success("Job successfully approved") }
    )
    const { pallet } = usePalletContext()

    return (
        <Formik
            initialValues={{ curatorComment: "" }}
            onSubmit={values => {
                const curatorComment = values.curatorComment.trim()
                return approvePost({
                    variables: {
                        postId: props.post.uuid,
                        applylist: pallet!,
                        curatorComment,
                    },
                }).then(() => {
                    mixpanel.track(TRACK_EVENTS.JOB_POST_APPROVED, {
                        applylist_slug: pallet!,
                        post_uuid: props.post.uuid,
                    })
                    props.hideModal()
                })
            }}
            validationSchema={Yup.object().shape({
                curatorComment: whiteSpaceValidator().max(
                    512,
                    "Message must be less than 512 characters."
                ),
            })}
        >
            <FormModal
                isOpen={props.isOpen}
                title={`Approve ${props.post.jobTitle} at ${props.post.company.name}`}
                action="Approve post"
                withSecondaryAction
                hideModal={props.hideModal}
                width="small"
            >
                <StyledTextArea
                    name="curatorComment"
                    label="Curator Comment"
                    sideDescription="(optional)"
                    placeholder="Adding a personal note lets candidates know why this job is awesome"
                    rows={6}
                    width={1}
                    autoFocus
                />
            </FormModal>
        </Formik>
    )
}

const StyledTextArea = styled(TextAreaField)`
    & textarea {
        width: 100%;
    }
`

export default ApprovePostModal
