import styled from "@emotion/styled"
import { theme } from "@pallet-hq/hegel"
import ReactTooltip from "react-tooltip"
import { useHasMounted } from "utils/hooks"

const Tooltip = ({ ...props }) => {
    // don't render on server due to https://github.com/wwayne/react-tooltip/issues/675
    const isMounted = useHasMounted()

    return isMounted ? (
        <StyledReactTooltip
            place="bottom"
            backgroundColor={theme.colors.gray700}
            textColor={theme.colors.white}
            {...props}
        />
    ) : null
}

export default Tooltip

const StyledReactTooltip = styled(ReactTooltip)`
    padding: 0 ${theme.space.xsmall} !important;
`
