import Tooltip from "components/Tooltip"
import UnstyledButton from "../UnstyledButton"

type TooltipButtonProps = {
    tooltip: string
    [x: string]: any
    delay?: number
}

const TooltipButton = ({
    tooltip,
    delay = 500,
    ...props
}: TooltipButtonProps) => (
    <div>
        <div data-tip={tooltip} data-delay-show={delay}>
            <UnstyledButton aria-label={tooltip} {...props} />
        </div>
        <Tooltip />
    </div>
)

export default TooltipButton
