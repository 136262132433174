import { gql } from "@apollo/client"
import { Box, Button, theme } from "@pallet-hq/hegel"
import { MouseEvent, useState } from "react"
import ApprovePostModal from "components/Modals/ApprovePostModal/ApprovePostModal"
import RejectPostModal from "components/Modals/RejectPostModal/RejectPostModal"
import { ApproveDeny_post } from "__generated__/ApproveDeny_post"

const ApproveDenyFragment = gql`
    fragment ApproveDeny_post on PostType {
        uuid
        jobTitle
        company {
            name
        }
        status(applylist: $applylist)
    }
`

export interface ApproveDenyProps {
    post: ApproveDeny_post
}

const ApproveDenyButtons = (props: ApproveDenyProps) => {
    const [isApproveModalOpen, setApproveModalOpen] = useState<boolean>(false)
    const [isRejectModalOpen, setRejectModalOpen] = useState<boolean>(false)

    return (
        <Box
            display="flex"
            flexWrap={["wrap", "nowrap"]}
            flexDirection={["column-reverse", "row"]}
            style={{ gap: theme.space.xsmall }}
        >
            <Button
                variant="secondary"
                size="small"
                width={[1, "auto"]}
                onClick={(e: any) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setRejectModalOpen(true)
                }}
            >
                Decline
            </Button>
            <Button
                size="small"
                width={[1, "auto"]}
                onClick={(e: MouseEvent) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setApproveModalOpen(true)
                }}
            >
                Accept
            </Button>
            <ApprovePostModal
                post={props.post}
                isOpen={isApproveModalOpen}
                hideModal={() => {
                    setApproveModalOpen(false)
                }}
            />
            <RejectPostModal
                post={props.post}
                isOpen={isRejectModalOpen}
                hideModal={() => {
                    setRejectModalOpen(false)
                }}
            />
        </Box>
    )
}

ApproveDenyButtons.fragment = ApproveDenyFragment
export default ApproveDenyButtons
