/*
 * Wrapper around the Hegel LinkButton component to provide "smart" linking
 */

import {
    LinkButton as HegelLinkButton,
    LinkButtonProps,
} from "@pallet-hq/hegel"
import SmartLink from "./SmartLink"

const LinkButton = (props: LinkButtonProps) => (
    <SmartLink href={props.href}>
        <HegelLinkButton {...props} />
    </SmartLink>
)

export default LinkButton
