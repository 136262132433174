export const jsonToMarkdown = (s: string) => {
    try {
        let mkdwn: string = ""
        const elements = JSON.parse(s)
        elements?.forEach((element: any) => {
            if (
                (!element.content || !element.content.trim()) &&
                element.type !== "list"
            )
                return
            if (element.type === "header") mkdwn += `##### ${element.content}\n`
            if (element.type === "body") mkdwn += `${element.content}\n\n`
            if (element.type === "list") {
                mkdwn += `##### ${element.header}\n`
                element.bullets?.forEach((bullet: string) => {
                    if (bullet) mkdwn += `- ${bullet}\n`
                })
            }
        })
        return mkdwn
    } catch (e) {
        return s
    }
}

export const formatJobTooltipText2 = (postData: { shortLabel: string }[]) => {
    if (postData.length <= 0) return null
    if (postData.length == 1)
        return {
            text: postData[0].shortLabel,
            tooltip: "",
        }
    const maxStringLength = 20
    const longDescription = postData
        .map(postData => postData.shortLabel)
        .join(" · ")

    if (longDescription.length > maxStringLength)
        return {
            text: `${postData[0].shortLabel} +${postData.length - 1}`,
            tooltip: longDescription,
        }
    else
        return {
            text: longDescription,
            tooltip: "",
        }
}

// used for displaying job experience and locations
export const formatJobTooltipText = (postData: { shortName: string }[]) => {
    if (postData.length <= 0) return null

    const maxStringLength = 15
    const longDescription = postData
        .map(postData => postData.shortName)
        .join(" · ")
    const isTruncated = postData[0].shortName.length >= maxStringLength
    const truncatedDescription = postData[0].shortName.substring(
        0,
        postData.length > 1
            ? maxStringLength - `+${postData.length - 1}`.length
            : maxStringLength
    )
    const shortDescription = `${truncatedDescription} +${postData.length - 1}`

    return postData.length > 1
        ? {
              text: shortDescription,
              tooltip: longDescription,
          }
        : {
              text: truncatedDescription,
              tooltip: isTruncated ? longDescription : "",
          }
}
