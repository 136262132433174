import { notify } from "@pallet-hq/hegel"
import { FiShare2 } from "react-icons/fi"
import TooltipButton from "../TooltipButton"

const copyUrlToClipboard = (location: string): Promise<void> => {
    return navigator.clipboard.writeText(location)
}

export const onShareClick = async (location: string) => {
    try {
        await copyUrlToClipboard(location)
    } catch (e) {
        console.error(e)
    }
    // success return toast notif
    notify.success("URL copied to clipboard 🔗")
}

interface ShareButtonProps {
    location: string
}
const ShareButton = (props: ShareButtonProps) => (
    <TooltipButton
        tooltip="Copy URL"
        onClick={() => onShareClick(props.location)}
    >
        <FiShare2 size={24} />
    </TooltipButton>
)

export default ShareButton
