import { gql, useMutation } from "@apollo/client"
import { FormModal, notify, TextAreaField } from "@pallet-hq/hegel"
import { Formik } from "formik"
import mixpanel from "mixpanel-browser"
import { TRACK_EVENTS } from "constants/events"
import { RejectPostMutation } from "__generated__/RejectPostMutation"
import { ApproveDeny_post } from "__generated__/ApproveDeny_post"
import { usePalletContext } from "utils/hooks/usePalletContext"

const REJECT_POST_MUTATION = gql`
    mutation RejectPostMutation(
        $postId: String!
        $message: String
        $applylist: String!
    ) {
        rejectPost(postId: $postId, message: $message, applylist: $applylist) {
            listing {
                id
                statusEnum
                isReviewable
                isListed
                applylist {
                    id
                    postCount
                    reviewCount
                }
            }
        }
    }
`

export type RejectPostModalProps = {
    post: ApproveDeny_post
    isOpen: boolean
    hideModal: () => void
}

const RejectPostModal = (props: RejectPostModalProps) => {
    const [rejectPost] = useMutation<RejectPostMutation>(REJECT_POST_MUTATION)
    const { pallet } = usePalletContext()

    return (
        <Formik
            initialValues={{ message: "" }}
            onSubmit={(values: { message: string }) => {
                const message = values.message.trim()
                return rejectPost({
                    variables: {
                        postId: props.post.uuid,
                        message: message,
                        applylist: pallet!,
                    },
                }).then(() => {
                    mixpanel.track(TRACK_EVENTS.JOB_POST_REJECTED, {
                        applylist_slug: pallet!,
                        post_uuid: props.post.uuid,
                    })
                    notify.success("This post has successfully been rejected.")
                })
            }}
        >
            <FormModal
                title={`Rejecting post ${props.post.jobTitle} at ${props.post.company.name}`}
                action="Reject post"
                withCancel
                width="small"
                hideModal={props.hideModal}
                isOpen={props.isOpen}
            >
                <TextAreaField
                    name="message"
                    label="Rejection message"
                    sideDescription="(optional)"
                    rows={4}
                    width={1}
                    autoFocus
                />
            </FormModal>
        </Formik>
    )
}

export default RejectPostModal
